<script setup lang="ts">
import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from 'lucide-vue-next';
import { Direction } from 'shared-components';
import type { UseSwipeDirection } from '@vueuse/core';
import type { Ref } from 'vue';

interface CarouselItem {
  goToSlide: (
    indexNextSlide: number,
    transition: boolean,
    usedAsNavigation: boolean
  ) => boolean;
}

// the size comes from the empty containers already having a padding of 10
const MINIMUM_CONTAINER_SIZE = 10;
const SWIPE_LENGTH_HALF = 50;

withDefaults(defineProps<{
  dots?: boolean;
  infinite?: boolean;
  initialSlide?: number;
  navButtons?: boolean;
  slidesToScroll?: number;
  speed?: number;
  useAsNavigationFor?: CarouselItem[];
}>(), { dots: true,infinite: true,initialSlide: 0,navButtons: true,slidesToScroll: 1,speed: 300,useAsNavigationFor: () => ([]), })

const emit = defineEmits<{
  beforeChange: [ {
    currentSlide: number;
    nextSlide: number;
  } ];
}>();

const carouselWrapper = ref<HTMLElement | null>(null);
const { width: widthCarouselWrapper } = useElementSize(carouselWrapper);
const itemsWrapper = ref<HTMLElement | null>(null);
const { width: widthItemsWrapperBrowser } = useElementSize(itemsWrapper);
const scroller = ref<HTMLElement | null>(null);
const slides: Ref<HTMLElement[]> = ref([]);
const slidesClonedBefore: Ref<HTMLElement[]> = ref([]);
const slidesClonedAfter: Ref<HTMLElement[]> = ref([]);
const singleItem = ref<HTMLElement | null>(null);
const widthSingleItem = ref<number>(0);

const { width: windowWidth } = useWindowSize();

const currentSlide = ref(0);
const translateX = ref(0);
const transitionDelay = ref(0);

const showClones = computed(() => __props.infinite);

const countSlides = computed(() => itemsWrapper.value?.children.length || 1);

const widthItemsWrapper = computed(() => widthSingleItem.value * countSlides.value);

const slidesAll = computed(() => (showClones.value
  ? [
    ...slidesClonedBefore.value,
    ...slides.value,
    ...slidesClonedAfter.value,
  ]
  : slides.value));

const canGoToPrev = computed(() => (__props.infinite || currentSlide.value > 0));

const canGoToNext = computed(() => (__props.infinite || currentSlide.value < countSlides.value - 1));

const style = computed(() => `--carousel-scroller-transform: translate(${translateX.value}px);
  --carousel-scroller-transition: transform ease ${transitionDelay.value}ms`);

const calculateSingleItemWidth = () => {
  const { width } = useElementSize(singleItem, {
    height: 0,
    width: MINIMUM_CONTAINER_SIZE,
  }, { box: 'border-box' });

  widthSingleItem.value = width.value;
};

const goToSlide = (
  indexNextSlide: number,
  transition = true,
  usedAsNavigation = false,
) => {
  if (!usedAsNavigation) {
    __props.useAsNavigationFor.forEach((carousel) => {
      if (carousel) {
        carousel.goToSlide(indexNextSlide, transition, true);
      }
    });
  }

  let nextSlidePossible = indexNextSlide;

  if (transition) {
    nextSlidePossible = ((nextSlidePossible % countSlides.value) + countSlides.value)
      % countSlides.value;

    emit('beforeChange', {
      currentSlide: currentSlide.value,
      nextSlide: nextSlidePossible,
    });

    currentSlide.value = nextSlidePossible;

    if (indexNextSlide !== nextSlidePossible) {
      setTimeout(() => {
        goToSlide(nextSlidePossible, false);
      }, __props.speed);
    }
  }

  let translateXParam = indexNextSlide * widthSingleItem.value * __props.slidesToScroll;

  const isContainerScrollable = widthCarouselWrapper.value < widthItemsWrapper.value;

  const translateXExceedsContainer = Math.abs(translateXParam)
    > widthItemsWrapper.value - widthCarouselWrapper.value;

  if (!__props.infinite) {
    if (!isContainerScrollable) {
      translateXParam = 0;
    } else if (translateXExceedsContainer) {
      translateXParam = widthItemsWrapper.value - widthCarouselWrapper.value;
    }
  }

  transitionDelay.value = transition
    ? __props.speed
    : 0;

  const additionalWrapperSize = __props.infinite
    ? widthItemsWrapper.value
    : 0;

  if (__props.infinite || (currentSlide.value <= countSlides.value)) {
    translateX.value = -1 * (translateXParam + additionalWrapperSize);
  }
  return true;
};

const goToNext = () => {
  if (canGoToNext.value) {
    goToSlide(currentSlide.value + 1);
  }
};

const goToPrev = () => {
  if (canGoToPrev.value) {
    goToSlide(currentSlide.value - 1);
  }
};

const { lengthX } = useSwipe(scroller, {
  onSwipeEnd(_, direction: UseSwipeDirection) {
    if (Math.abs(lengthX.value) > SWIPE_LENGTH_HALF) {
      if (direction === Direction.LEFT) {
        goToNext();
      } else if (direction === Direction.RIGHT) {
        goToPrev();
      }
    }
  },
  passive: true,
});

const itemsWrapperStyle = computed(() => (widthItemsWrapperBrowser.value !== widthItemsWrapper.value
  ? { width: `${widthItemsWrapper.value}px` }
  : {}));

/**
 * Prepare slides classes and styles
 */
const prepareSlides = () => {
  if (itemsWrapper.value && itemsWrapper.value.children) {
    slides.value = htmlCollectionToArray(itemsWrapper.value.children);

    if (showClones.value) {
      slidesClonedBefore.value = slides.value;
      slidesClonedAfter.value = slides.value;
    }

    slidesAll.value.forEach((slide: HTMLElement) => {
      slide.classList.add('block', 'grow-1', 'shrink-0');
    });

    [ singleItem.value ] = htmlCollectionToArray(itemsWrapper.value.children);
    calculateSingleItemWidth();
  }
};

const prepareCarousel = () => {
  if (currentSlide.value === null && countSlides.value) {
    currentSlide.value = __props.initialSlide;
  }

  if (currentSlide.value > countSlides.value) {
    currentSlide.value = countSlides.value - 1;
  }

  goToSlide(currentSlide.value, false, false);
};

const initCarousel = () => {
  prepareSlides();
  prepareCarousel();
};

watch(windowWidth, () => {
  initCarousel();
});

watch(widthItemsWrapperBrowser, () => {
  prepareSlides();
});

onMounted(() => {
  nextTick(() => initCarousel());
});

defineExpose({
  goToSlide,
  initCarousel,
  prepareCarousel,
});
</script>

<template>
  <div
    class="carousel-component group/carousel outline-hidden focus-visible:outline-hidden"
    data-cy="carousel-component"
  >
    <div
      ref="carouselWrapper"
      class="carousel-component__list relative flex max-h-full w-full overflow-hidden"
    >
      <div
        ref="scroller"
        class="carousel-component__scroller flex h-full min-w-min flex-row flex-nowrap"
        :style="style"
      >
        <div
          v-if="showClones"
          ref="slidesClonedBefore"
          class="carousel-component__slides carousel-component__slides--cloned grow-1 flex h-full shrink-0 flex-row flex-nowrap items-center justify-start"
          :style="itemsWrapperStyle"
        >
          <slot />
        </div>
        <div
          ref="itemsWrapper"
          class="carousel-component__slides carousel-component__slides--regular grow-1 flex h-full shrink-0 flex-row flex-nowrap items-center justify-start"
        >
          <slot />
        </div>
        <div
          v-if="showClones"
          ref="slidesClonedAfter"
          class="carousel-component__slides carousel-component__slides--cloned grow-1 flex h-full shrink-0 flex-row flex-nowrap items-center justify-start"
          :style="itemsWrapperStyle"
        >
          <slot />
        </div>
      </div>
    </div>
    <UiFloatingButton
      v-if="navButtons"
      class="absolute left-4 top-1/2 hidden size-10 -translate-y-5 group-hover/carousel:block"
      :icon="ArrowLeftIcon"
      @click="goToPrev"
    />
    <UiFloatingButton
      v-if="navButtons"
      class="absolute right-4 top-1/2 hidden size-10 -translate-y-5 group-hover/carousel:block sm:left-[444px] sm:right-auto md:left-[min(100%-404px,584px)] lg:left-[584px]"
      :icon="ArrowRightIcon"
      @click="goToNext"
    />
    <CarouselDots
      v-if="dots"
      :count-slides="countSlides"
      :current-slide="currentSlide"
      :go-to-slide="goToSlide"
    />
  </div>
</template>

<style scoped>
@reference '@/styles/global.css';

.carousel-component :deep(img) {
  @apply pointer-events-none;
}

.carousel-component__scroller {
    min-width: min-content;
    transition: var(--carousel-scroller-transition);
    transform: var(--carousel-scroller-transform);
  }
</style>
