<script setup lang="ts">
import {
  PriceSize,
  type PriceSizeType,
} from '@/types/price';

const { formatCurrency } = useFormatter();

withDefaults(defineProps<{
  active?:boolean;
  highlighted?: boolean;
  horizontal?: boolean;
  priceCurrent: number;
  priceOriginal?: number;
  pricePrefix?: string;
  showDescription?: boolean;
  size?: PriceSizeType;
}>(), { highlighted: false,horizontal: false,priceOriginal: 0.0,pricePrefix: '',showDescription: true,size: () => (PriceSize.Medium), })

const translationKeys = {
  description: 'price.description',
  shipping: 'price.shipping',
};

const replaceSpaceWithNonBreakingSpace = (text: string) => text
  .replace(
    / /g,
    '\u00A0',
  );

const formatPrice = (priceforFormatting: number) => formatCurrency(priceforFormatting);

const showOriginalPrice = computed(() => (
  !!__props.priceCurrent && !!__props.priceOriginal && __props.priceCurrent < __props.priceOriginal
));

const formattedOriginalPrice = computed(() => (showOriginalPrice.value
  ? replaceSpaceWithNonBreakingSpace(formatPrice(__props.priceOriginal))
  : ''));

const formattedCurrentPrice = computed(() => {
  const formattedPrice = formatPrice(__props.priceCurrent);
  return replaceSpaceWithNonBreakingSpace(formattedPrice);
});

const sizeClassPriceCurrent = computed(() => ({
  'text-sm': __props.size === PriceSize.Medium,
  'text-xl': __props.size === PriceSize.Large,
  'text-xs': __props.size === PriceSize.Small,
}));

const sizeClassPriceOriginal = computed(() => ({
  'text-[10px] -mb-1.5': __props.size === PriceSize.Small,
  'text-sm': __props.size !== PriceSize.Small,
}));
</script>

<template>
  <div class="ui-price flex flex-col flex-wrap items-end justify-items-center">
    <div
      class="ui-price__items flex"
      :class="[horizontal ? 'flex-row items-center' : 'flex-col items-end', PriceSize.Small ? '' : 'gap-2']"
    >
      <UiPriceItem
        v-if="showOriginalPrice"
        crossed
        class="ui-price__original scale-90 font-normal"
        :active="active"
        :class="[sizeClassPriceOriginal]"
        :price="formattedOriginalPrice"
      />
      <UiPriceItem
        class="ui-price__current"
        :active="active"
        :class="[sizeClassPriceCurrent]"
        :highlighted="highlighted && showOriginalPrice"
        :price="formattedCurrentPrice"
        :price-prefix="pricePrefix"
      />
    </div>
    <div
      v-if="showDescription"
      class="text-xxs font-normal text-grey-semidark"
    >
      {{ `${$t(translationKeys.description)} ${$t(translationKeys.shipping)}` }}
    </div>
  </div>
</template>
