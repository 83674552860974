import { useQuery } from '@tanstack/vue-query';
import { WISHLIST_API_BASE_URL } from 'configs';
import { getLocaleByCode } from 'localization';
import { Logger } from 'utils';

const useDesignLikes = (designId: string, { enabled = true }: {
  enabled?: boolean;
}) => {
  const { locale } = useI18n();
  const localeHeader = computed(() => ({ 'X-Locale': getLocaleByCode(locale.value)?.iso ?? 'en-GB' }));

  const {
    data: likes,
    refetch: fetchLikes,
  } = useQuery({
    enabled: enabled && import.meta.client,
    queryFn: async () => {
      const response = await fetch(`${WISHLIST_API_BASE_URL}/${designId}/likes`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          ...localeHeader.value,
        },
      });

      if (!response.ok) {
        Logger.error('Failed to fetch design likes', { error: await response.json() });
        return 0;
      }

      const { count } = (await response.json()) as { count: number };

      return count;
    },
    queryKey: [
      'design-likes',
      designId,
    ],
  });

  return {
    fetchLikes,
    likes,
  };
};

export { useDesignLikes };
