<script setup lang="ts">
import { BlendIcon } from 'lucide-vue-next';

const ANIMATION_DURATION = 2000;

withDefaults(defineProps<{
  animateButton: boolean;
}>(), {  })

const hasSeenButtonAnimation = useState('has-seen-button', () => false);

const animate = ref(false);

const shouldAnimate = computed(() => !hasSeenButtonAnimation.value && __props.animateButton);

onMounted(async () => {
  if (shouldAnimate.value) {
    setTimeout(() => {
      animate.value = true;
      setTimeout(() => {
        animate.value = false;
        hasSeenButtonAnimation.value = true;
      }, ANIMATION_DURATION);
    }, ANIMATION_DURATION);
  }
});
</script>

<template>
  <div class="absolute bottom-[-10px] group/similar-button right-[4%] flex items-center overflow-hidden rounded-full bg-purple-light group-hover/tile:bottom-[110%] group-hover/similar-button:pr-4">
    <div class="flex h-9 w-9 items-center justify-center">
      <BlendIcon
        class="size-4 text-purple"
        stroke-width="3"
      />
    </div>
    <span
      class="max-w-0 whitespace-nowrap text-dark text-xxs font-medium transition-all duration-300 group-hover/similar-button:max-w-[200px] group-hover/similar-button:pr-4"
      :class="[animate ? 'max-w-[200px] pr-4' : '']"
    >
      {{ $t('productDetail.similarDesigns.headline') }}
    </span>
  </div>
</template>
