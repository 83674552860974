import { type ProductType } from 'configs';
import type { DesignProductVariant } from 'shop-frontend/graphql-bff';

interface Entry {
  frameGroups?: Array<string> | null;
  genders?: Array<string> | null;
  perspectives?: Array<string> | null;
  sizes?: Array<string> | null;
  slotId: string;
}

interface PreselectedProps {
  frameGroup?: string | null;
  gender?: string | null;
  perspective?: string | null;
  size?: string | null;
}

const compareBooleans = (a: boolean, b: boolean): number => {
  if (a === b) {
    return 0;
  }
  return a
    ? -1
    : 1;
};

const getProductVariantForImages = (currentProductType: ProductType | null, productVariants: DesignProductVariant[]) => {
  if (currentProductType === 'hahnemuehle') {
    return productVariants.find((variant) => variant.type === 'poster');
  }

  if (currentProductType === 'forexplatte') {
    return productVariants.find((variant) => variant.type === 'aludibond');
  }

  return productVariants.find((variant) => variant.type === currentProductType);
};

const getAspectRatioFromSize = (size: string) => {
  if (size.includes('x')) {
    const [
      width,
      height,
    ] = size.split('x').map(Number);
    return width / height;
  }
  return null;
};

const sortSceneTemplateSlots = (array: Entry[], preselected: PreselectedProps): Entry[] => [ ...array ].sort((a, b) => {
  // Priority order based on the specified criteria
  // Higher priority criteria are checked first

  // 1. Sort by frameGroup
  if (!preselected.frameGroup || preselected.frameGroup === 'without') {
    const aHasFrameGroup = a.frameGroups === null;
    const bHasFrameGroup = b.frameGroups === null;
    if (aHasFrameGroup !== bHasFrameGroup) {
      return compareBooleans(aHasFrameGroup, bHasFrameGroup);
    }
  } else if (preselected.frameGroup) {
    // 1. First priority: Sort by whether frameGroup matches the preselected frameGroup
    const aHasFrameGroupMatch = a.frameGroups?.includes(preselected.frameGroup) ?? false;
    const bHasFrameGroupMatch = b.frameGroups?.includes(preselected.frameGroup) ?? false;

    if (aHasFrameGroupMatch !== bHasFrameGroupMatch) {
      return compareBooleans(aHasFrameGroupMatch, bHasFrameGroupMatch);
    }

    // 2. Second priority: Sort by whether frameGroup is set (not null/undefined)
    const aHasFrameGroup = a.frameGroups !== null && a.frameGroups !== undefined;
    const bHasFrameGroup = b.frameGroups !== null && b.frameGroups !== undefined;

    if (aHasFrameGroup !== bHasFrameGroup) {
      return compareBooleans(aHasFrameGroup, bHasFrameGroup);
    }

    return 0;
  }

  // 2. Sort by size (or aspectRatio, especially for cushions)
  if (preselected.size) {
    const aHasSize = a.sizes?.includes(preselected.size) ?? false;
    const bHasSize = b.sizes?.includes(preselected.size) ?? false;

    if (!aHasSize && !bHasSize) {
      const aAspectRatios = a.sizes?.map((size) => getAspectRatioFromSize(size));
      const bAspectRatios = b.sizes?.map((size) => getAspectRatioFromSize(size));
      const aspectRatioCurrent = getAspectRatioFromSize(preselected.size);

      const aHasAspectRatio = aAspectRatios?.includes(aspectRatioCurrent) ?? false;
      const bHasAspectRatio = bAspectRatios?.includes(aspectRatioCurrent) ?? false;

      if (aHasAspectRatio !== bHasAspectRatio) {
        return compareBooleans(aHasAspectRatio, bHasAspectRatio);
      }
    }

    if (aHasSize !== bHasSize) {
      return compareBooleans(aHasSize, bHasSize);
    }
  }

  // 3. Sort by gender
  if (preselected.gender) {
    const aHasGender = a.genders?.includes(preselected.gender) ?? false;
    const bHasGender = b.genders?.includes(preselected.gender) ?? false;
    if (aHasGender !== bHasGender) {
      return compareBooleans(aHasGender, bHasGender);
    }
  } else {
    const aHasGender = !!a.genders;
    const bHasGender = !!b.genders;
    if (aHasGender !== bHasGender) {
      return compareBooleans(aHasGender, bHasGender);
    }
  }

  // 4. Sort by perspective
  if (preselected.perspective) {
    const aHasPerspective = a.perspectives?.includes(preselected.perspective) ?? false;
    const bHasPerspective = b.perspectives?.includes(preselected.perspective) ?? false;
    if (aHasPerspective !== bHasPerspective) {
      return compareBooleans(aHasPerspective, bHasPerspective);
    }
  }

  // If all specified criteria are equal, maintain original order
  return 0;
});

export {
  getProductVariantForImages,
  sortSceneTemplateSlots,
};
