<script setup lang="ts">
import { HeartIcon } from 'lucide-vue-next';
import type { ProductType } from 'configs';

const ANIMATION_DURATION = 1000;

withDefaults(defineProps<{
  designId: string;
  productType: ProductType;
  showCount?: boolean;
  size: 'sm' | 'md';
}>(), {  })

const {
  addToWishlist,
  wishlistItems,
} = useWishlist();

const {
  fetchLikes,
  likes,
} = useDesignLikes(__props.designId, { enabled: __props.showCount });

const shouldAnimate = ref(false);

const isLiked = computed(() => wishlistItems.value?.some((item) => String(item.designId) === __props.designId && item.productType === __props.productType));

const handleClick = () => addToWishlist({
  designId: Number(__props.designId),
  productType: __props.productType,
});

const counterVisible = computed(() => __props.showCount && !!likes.value);

const formattedLikes = computed(() => {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  if (likes.value && likes.value > 999) {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    return `${(likes.value / 1000).toFixed(1)}k`;
  }

  return likes.value;
});

onMounted(() => {
  if (__props.showCount) {
    fetchLikes();
  }
});

watch(
  isLiked,
  (liked, oldLiked) => {
    if (liked && !oldLiked) {
      shouldAnimate.value = true;

      setTimeout(() => {
        shouldAnimate.value = false;
      }, ANIMATION_DURATION);
    }
  },
);
</script>

<template>
  <button
    :class="cn('like-button size-8 bg-white/80 shadow-md flex items-center justify-center transition-transform duration-150 hover:scale-105 rounded-full', { 'md:size-10': size === 'md', 'bg-white': isLiked, 'md:w-auto w-auto md:min-w-10 min-w-8 px-3': counterVisible, 'hop-animation': shouldAnimate })"
    @click.prevent="handleClick"
  >
    <span
      class="absolute inset-0 scale-0 rounded-full bg-purple-light"
      :class="{ 'button-fill-animation': shouldAnimate }"
    />
    <HeartIcon
      :class="[isLiked ? 'text-primary size-5 fill-purple stroke-purple' : '',
               { 'heart-spin-animation': shouldAnimate }]"
    />

    <span
      v-if="counterVisible"
      class="relative z-10 ml-1 mr-0.5 text-sm font-medium"
    >
      {{ formattedLikes }}
    </span>
  </button>
</template>

<style scoped>
.heart-spin-animation {
  animation-name: heart-spin;
  animation-duration: 1000ms;
  animation-iteration-count: 1;
}

@keyframes heart-spin {
  0% {  transform: rotateY(0deg) translateY(0px); fill: transparent; stroke: var(--color-dark); }
  50% {  transform: rotateY(180deg) translateY(-4px); fill: var(--color-purple); stroke: var(--color-purple); }
  80% {  transform: rotateY(360deg) translateY(2px); }
  100% {  transform: rotateY(360deg) translateY(0px); fill: var(--color-purple); stroke: var(--color-purple); }
}

.button-fill-animation {
  animation-name: button-fill;
  animation-duration: 1000ms;
  animation-iteration-count: 1;
}

@keyframes button-fill {
  0% {  transform: scale(0); }
  50% {  transform: scale(1); }
  100% {  transform: scale(0); }
}

.hop-animation {
  animation-name: hop;
  animation-duration: 1000ms;
  animation-iteration-count: 1;
}

@keyframes hop {
  0% {  transform: translateY(0px); }
  50% {  transform: translateY(-4px); }
  100% {  transform: translateY(0px); }
}
</style>
