const useProductImageUrl = () => {
  const PRODUCT_IMAGE_PROXY_BASE_URL = 'https://cdn.juniqe.com/image';

  const { createUrlWithSearchParams } = useBaseUrl();

  const createProductImageUrlForSceneMask = (
    designIdentifier: string,
    sceneTemplateSlotId: string,
    options: {
      aspectRatio?: string;
      color?: string;
      size?: string;
    },
  ): string => createUrlWithSearchParams(
    `${PRODUCT_IMAGE_PROXY_BASE_URL},${designIdentifier},${sceneTemplateSlotId}.jpg`,
    options,
    true,
  );

  return { createProductImageUrlForSceneMask };
};

export { useProductImageUrl };
