<script setup lang="ts">
import {
  frameDefaultProduct,
  isFramedProductType,
  type ProductType,
  productTypes,
} from 'configs';
import {
  getProductVariantForImages,
  sortSceneTemplateSlots,
} from 'utils';
import { DesignType } from '#gql/default';
import { SPLIT_EVENTS } from '@/config/splitio';
import type { DesignsQuery } from '#gql';
import type { FilterCategoryWithFacets } from '@/types/list-page';

type Design = NonNullable<DesignsQuery['designs']['nodes']>[number];

withDefaults(defineProps<{
  activeFilters?: FilterCategoryWithFacets;
  activeProductTypes?: ProductType[];
  altTag?: string;
  animateButton?: boolean;
  design: Design;
  enableThumbnailCarousel?: boolean;
  frameGroupToSet?: string | null | undefined;
  hideProductTypePrefix?: boolean;
  imageSizes?: string;
  productType?: ProductType;
  showCreator?: boolean;
  showGallery?: boolean;
  showProductType?: boolean;
  showProductTypeInsteadOfTitle?: boolean;
  showSimilarDesigns?: boolean;
}>(), { animateButton: false,enableThumbnailCarousel: true,frameGroupToSet: null,hideProductTypePrefix: false,showCreator: true,showGallery: true,showSimilarDesigns: false, })

const {
  locale,
  t,
} = useI18n();

const { createProductContextWithDefaults } = useProductContext();
const { createProductImageUrlForSceneMask } = useProductImageUrl();
const { createProductDetailPath } = useProductDetail();
const { getIsMatureContentEnabled } = useMatureContent();
const { trackEvent } = useSplit();

const shopNameText = `${t('common.byCreator')} ${__props.design.shop.shopName}`;

const showTooltip = ref(false);

const showThumbnailCarousel = ref(false);

defineEmits<{
  'similarDesignsClicked': [];
}>();

const showTags = (show: boolean) => {
  showTooltip.value = show;
};

const setThumbnailCarouselVisible = (visible: boolean) => {
  if (!__props.enableThumbnailCarousel) {
    return;
  }

  showThumbnailCarousel.value = visible;
};

const randomProductType = useState<ProductType>(`design-tile-${__props.design.objectId}-${__props.productType}-${JSON.stringify(__props.activeFilters)}-${JSON.stringify(__props.activeProductTypes)}`);
const frameGroup = useState<string | null>(`frame-group-${__props.design.objectId}-${__props.productType}-${JSON.stringify(__props.activeFilters)}-${JSON.stringify(__props.activeProductTypes)}`, () => __props.frameGroupToSet);

const designAvailableProducts = __props.design.productVariants.map((productVariant) => productVariant.type);

const setRandomProductType = () => {
  const filteredProducts = __props.activeProductTypes?.length
    ? __props.activeProductTypes
    : productTypes;

  const filteredProductsMapFrames = filteredProducts.map((singleType) => (isFramedProductType(singleType)
    ? frameDefaultProduct(singleType)
    : { default: singleType }));

  const possibleProducts = filteredProductsMapFrames.filter((product) => (typeof product === 'object' && product !== null
    ? designAvailableProducts.includes(Object.values(product)[0])
    : false));

  const randomUnframedProductType = getRandomUnframedProductType(Array.from(new Set(possibleProducts.flatMap((p) => (p
    ? Object.values(p)
    : [])))));

  const randomProduct = getRandomArrayItem(possibleProducts.filter((p) => p && Object.values(p)[0] === randomUnframedProductType)!);

  if (randomProduct) {
    [
      [
        frameGroup.value,
        randomProductType.value,
      ],
    ] = Object.entries(randomProduct);
  }
};

const conditionProductNotInActiveTypes = __props.activeProductTypes?.length
  && (!__props.activeProductTypes.includes(randomProductType.value) && !__props.activeProductTypes.some((product) => product.startsWith(randomProductType.value)));

if ((!__props.productType || !designAvailableProducts.includes(__props.productType)) && (!randomProductType.value || conditionProductNotInActiveTypes)) {
  setRandomProductType();
}

const currentProductType = computed<ProductType>(() => {
  if (__props.productType && __props.design.productVariants.some((variant) => variant.type === __props.productType)) {
    return __props.productType;
  }

  return randomProductType.value;
});

const getProductVariant = computed(() => __props.design.productVariants.find((variant) => variant.type === currentProductType.value));

const getAspectRatioFromVariant = computed(() => {
  const productAspectRatioFromVariant = __props.design.productVariants.find((variant) => variant.type === currentProductType.value)?.aspectRatios;

  return productAspectRatioFromVariant && productAspectRatioFromVariant.length
    ? productAspectRatioFromVariant[0]
    : undefined;
});

const productContext = computed(() => createProductContextWithDefaults({
  aspectRatio: getAspectRatioFromVariant.value,
  frameGroup: frameGroup.value,
  productOptions: { color: __props.activeFilters?.productColor?.[0] || getProductVariant.value?.defaultColor },
  productType: currentProductType.value,
}));

const productLinkWithProductContext = computed<string>(() => {
  let productLink = createProductDetailPath({
    keepQueryParams: false,
    product: {
      designId: __props.design.objectId,
      designTitle: __props.design.metadata.title.text,
      type: currentProductType.value,
    },
    productContext: productContext.value,
  });

  if (__props.activeFilters?.gender?.length) {
    productLink = addQueryParamsToPath(productLink, { gender: __props.activeFilters.gender[0] });
  }

  return productLink;
});

const imagesForProductType = ref<string[]>([]);
const imagesKey = computed(() => `product-images-${__props.design.objectId}-${currentProductType.value}-${JSON.stringify(__props.activeFilters)}`);

const setImagesForProductType = () => {
  const productVariantForImages = getProductVariantForImages(currentProductType.value, __props.design.productVariants);
  if (!productVariantForImages?.sceneTemplateSlots?.length || !getProductVariant.value) {
    imagesForProductType.value = [];
    return;
  }

  const pages = getPagesForProductVariantBySize(getProductVariant.value, productContext.value.size);

  const sortedSceneTemplateSlots = sortSceneTemplateSlots(
    productVariantForImages.sceneTemplateSlots,
    {
      frameGroup: productContext.value.frameGroup,
      gender: __props.activeFilters?.gender?.length === 1
        ? __props.activeFilters.gender[0]
        : null,
      perspective: pages.length === 1 && pages[0] === 'back'
        ? pages[0]
        : 'front',
      size: productContext.value.size,
    },
  );

  imagesForProductType.value = sortedSceneTemplateSlots.map((sceneTemplateSlot) => {
    const imageParams: Record<string, string> = {};

    if (productContext.value.color) {
      imageParams.color = productContext.value.color;
    }

    if (productContext.value.size) {
      imageParams.size = productContext.value.size;
    }

    if (getProductVariant.value?.aspectRatios?.length) {
      const [ firstAspectRatio ] = getProductVariant.value.aspectRatios;
      imageParams.aspectRatio = firstAspectRatio;
    }

    return createProductImageUrlForSceneMask(
      __props.design.objectId,
      sceneTemplateSlot.slotId,
      { ...imageParams },
    );
  }) ?? [];
};

const price = computed(() => __props.design.productVariants.find((productVariant) => productVariant.type === currentProductType.value)?.price);

watch(
  () => __props.activeProductTypes,
  () => {
    setRandomProductType();
  },
);

watch([
  currentProductType,
  () => __props.activeFilters,
], () => {
  setImagesForProductType();
});

onMounted(() => {
  setImagesForProductType();
});

const productTypeName = computed(() => (currentProductType.value
  ? t(`product.type.${currentProductType.value}`)
  : ''));

const productTypeLabel = computed(() => {
  if (locale.value === 'de' && productTypeName.value && !__props.hideProductTypePrefix) {
    return t('common.asProduct', { product: productTypeName.value });
  }

  return productTypeName.value;
});

const handleProductClick = () => {
  trackEvent(SPLIT_EVENTS.PDP_CLICK, {
    designId: __props.design.objectId,
    productType: currentProductType.value,
  });
};
</script>

<template>
  <div
    class="design-tile group/tile relative block w-full cursor-pointer rounded-lg shadow-lg shadow-dark/5 transition-shadow duration-150 hover:shadow-xl hover:shadow-dark/9"
    :class="[showProductType ? 'bg-grey-extra-light' : 'bg-white']"
    @mouseenter="setThumbnailCarouselVisible(true)"
  >
    <NuxtLink
      class="text-sm"
      :to="productLinkWithProductContext"
      @click="handleProductClick"
    >
      <div
        class="like-button-wrapper invisible absolute right-[4%] top-[4%] z-20 mt-1 flex transition-transform group-hover/tile:visible group-hover/tile:-translate-y-2.5 [@media(pointer:coarse)]:visible [@media(pointer:none)]:visible"
        @mouseenter="showTags(true)"
        @mouseleave="showTags(false)"
      >
        <DesignLikeButton
          size="sm"
          :design-id="design.objectId"
          :product-type="currentProductType"
        />
      </div>

      <div class="gallery-container relative z-10 aspect-328/297 w-full rounded-t-lg bg-grey-light transition-transform group-hover/tile:-translate-y-2.5">
        <ClientOnly v-if="showGallery">
          <ProductTileCarousel
            :key="imagesKey"
            :alt-tag="altTag"
            :animate-button="animateButton"
            :design-type="design.canvas.type"
            :image-sizes="imageSizes"
            :images="imagesForProductType"
            :is-mature-content="design.metadata.isMatureContent"
            :is-thumbnail-visible="enableThumbnailCarousel && showThumbnailCarousel"
            :object-id="design.objectId"
            :show-similar-designs="showSimilarDesigns"
            @similar-designs-clicked="$emit('similarDesignsClicked')"
          />
        </ClientOnly>
        <div v-else>
          <div class="relative flex items-center justify-center overflow-hidden">
            <UiMatureButton
              v-if="!getIsMatureContentEnabled && design.metadata?.isMatureContent"
              show-button
            />
            <NuxtResponsivePicture
              show-loading-skeleton
              aspect-ratio="640/580"
              class="w-full rounded-t-lg"
              provider="cloudfront"
              :alt-tag="altTag"
              :class="[design.metadata.isMatureContent && !getIsMatureContentEnabled ? 'blur-3xl' : 'blur-none']"
              :src="imagesForProductType[0]"
              :width="237"
            />
          </div>
          <DesignTypeBadge
            v-if="design.canvas.type === DesignType.CREATE && !showSimilarDesigns"
            class="absolute bottom-0 right-4 translate-y-1/2 hover:cursor-pointer"
            :type="design.canvas.type"
          />
        </div>
      </div>

      <div
        class="design-info z-0 overflow-hidden rounded-b-lg pt-2.5 transition-transform"
        :class="[
          showCreator ? 'group-hover/tile:translate-y-2.5' : 'group-hover/tile:translate-y-1.5',
          showProductType ? 'bg-grey-extra-light' : 'bg-white px-2.5 md:px-4'
        ]"
      >
        <div
          class="design-description transition-transform"
          :class="[showCreator ? 'h-12' : 'h-8 content-center', !showProductType ? 'mb-2 border-b border-solid border-grey md:mb-2.5' : 'px-2.5 md:px-4']"
        >
          <span
            class="link-content block max-w-full truncate text-xs font-medium"
            :class="[showCreator ? 'group-hover/tile:translate-y-5' : 'group-hover/tile:translate-y-0']"
            v-text="showProductTypeInsteadOfTitle ? productTypeName : design.metadata.title.text"
          />

          <p
            v-if="showCreator"
            class="m-0 block max-w-full truncate text-xs font-normal group-hover/tile:hidden"
            v-text="shopNameText"
          />
        </div>

        <div
          v-if="showProductType && productTypeLabel"
          class="bg-white px-2.5 pb-2 pt-2.5 transition-transform md:px-4 md:pb-2.5"
        >
          <span class="flex">
            <ProductTypeIcon
              class="mr-2 size-5 transition-transform group-hover:-translate-y-0.5"
              :product-type="currentProductType as ProductType"
            />
            <div class="text-xs">
              {{ productTypeLabel }}
            </div>
          </span>
        </div>

        <UiPrice
          v-if="price"
          horizontal
          class="tile-price flex justify-end px-2.5 py-2 transition-transform md:px-4 md:pb-2.5"
          size="medium"
          :class="{'bg-linear-to-b from-grey-light from-0% to-white to-50%': showProductType}"
          :highlighted="price.current !== price.original"
          :price-current="price.current"
          :price-original="price.original"
          :price-prefix="t('price.from')"
          :show-description="false"
        />
      </div>
    </NuxtLink>
  </div>
</template>
